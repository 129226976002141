import { gsap } from "gsap";

// if (document.querySelector([]))
const $verticalCarouselItems = document.querySelectorAll(
  ".vertical-carousel-item"
);
const $verticalCarouselNavItems = document.querySelectorAll(
  ".vertical-carousel-nav-item"
);

var verticalCarouselNavUpdate = function (n) {
  $verticalCarouselNavItems.forEach((element) => {
    
    element.classList.remove("active");
  });
  $verticalCarouselNavItems[n].classList.add("active");
};

$verticalCarouselItems.forEach((element, i) => {
  const verticalCarouselItem = new gsap.timeline({
    scrollTrigger: {
      trigger: element,
      start: "top 50%",
      end: "bottom 50%",
      markers: false,
      onEnter: () => {
        verticalCarouselNavUpdate(i);
      },
      onEnterBack: () => {
        verticalCarouselNavUpdate(i);
      }
    }
  });
});

const verticalCarouselSnap = new gsap.timeline({
  scrollTrigger: {
    trigger: "#vertical-carousel",
    start: "top top",
    end: "101% 100%",
    markers: false,
    onEnter: () => {
      document.documentElement.classList.add("vertical-gallery-snap");
    },
    onEnterBack: () => {
      document.documentElement.classList.add("vertical-gallery-snap");
    },
    onLeave: () => {
      document.documentElement.classList.remove("vertical-gallery-snap");
    }
  }
});

// NOTE This is just like the slide-in-content.js but we needed some custom positioning of start and stop because of the css snapping.  
const $verticalCarouselTextItems = document.querySelectorAll(
  ".vertical-carousel-fade-in"
);

$verticalCarouselTextItems.forEach((element) => {
  const verticalCarouselText = new gsap.timeline({
    scrollTrigger: {
      trigger: element,
      start: "top 90%",
      end: "bottom 95%",
      scrub: 1,
      markers: false
    }
  });

  verticalCarouselText.from(element, {
    opacity: 0,
    yPercent: 10,
    ease: "ease-out"
  });
});
