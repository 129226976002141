let elem = document.querySelectorAll(
  '[data-alias-name="featureAccordionRow"]'
);
featureAccordion(elem);

document.addEventListener("load", function () {
  elem = document.querySelectorAll(
    '[data-alias-name="featureAccordionRow"]'
  );
  featureAccordion(elem);
});

function featureAccordion(elem) {
  if (elem == null) return;
  
  elem.forEach((item,index) => {
    const { uniqueId } = item.dataset;
    const accordionItems = item.querySelectorAll(".accordion-item");
    const accordionHeaders = item.querySelectorAll(".accordion-header");
    const featureImages = item.querySelectorAll(".features-accordion-bg");
    const accordionContent = accordionItems[index].querySelector(".accordion-content");
    let activeIndex = 0;
    accordionContent.hidden = true;
    // set the max-height on the first element;
    const firstContent = accordionItems[activeIndex].querySelector(".accordion-content");
    firstContent.hidden = false;
    // firstContent.style.maxHeight = firstContent.scrollHeight + "px";

    const updateBackground = function (idx) {
      featureImages.forEach(function (image) {
        image.classList.remove("active");
      });
      document.getElementById(`features-accordion-bg-${uniqueId}-${idx}`).classList.add("active");
    };

    accordionHeaders.forEach((header) => {
      header.addEventListener("click", (e) => {
        activeIndex = header.dataset.index;
        const content =
          accordionItems[activeIndex].querySelector(".accordion-content");
        const contentHeight = content.scrollHeight;

        if (featureImages.length) {
          updateBackground(activeIndex);
        }

        accordionItems.forEach(function (item) {
          if (item !== header.parentNode) {
            item.classList.remove("active");
            item.querySelector(".accordion-content").style.maxHeight = null;
          }
        });

          // content.style.maxHeight = content.style.maxHeight
          // ? null
          // : contentHeight + "px";
        header.parentNode.classList.toggle("active");
        content.hidden = !header.parentNode.classList.contains('active');
      });
    });
  });
}
