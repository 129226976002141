import { gsap } from "gsap";
const $overflowGallery = document.querySelectorAll(".overflow-gallery");

$overflowGallery.forEach((element) => {
  const overflowAnimation = new gsap.timeline({
    scrollTrigger: {
      trigger: element,
      start: "top 95%",
      end: "bottom top",
      scrub: 0,
      markers: false,
      toggleClass: "slide-in",
    },
  });
});

export function playPause(event) {
  event.preventDefault();
  let videoContainer = event.target.closest(".js--video-container");
  let video = videoContainer.querySelector(".js--videoElement");
  let playBtn = videoContainer.querySelector(".playBtn");
  let pauseBtn = videoContainer.querySelector(".pauseBtn");
  if (video.paused) {
    playBtn.style.display = "none";
    pauseBtn.style.display = "block";
    video.play();
  } else {
    pauseBtn.style.display = "none";
    playBtn.style.display = "block";
    video.pause();
  }
}
