﻿import { gsap } from "gsap";
const $marqueElements = document.querySelectorAll(".marquee-section");

// event handlers for all the subnav links.
$marqueElements.forEach(function (element) {

    var guid = element.dataset.itemid;

    var marqueeSectionId = "#marquee-section-" + guid;
    var marqueeTextId = "#marquee-text-" + guid;

    const textAnimate = new gsap.timeline({
        scrollTrigger: {
            trigger: marqueeSectionId,
            start: "top 10%",
            end: "center top",
            scrub: 1.5,
            markers: false
        }
    });
    if(document.querySelector(marqueeTextId)!=null){
      textAnimate.to(marqueeTextId, {
          xPercent: -25,
          ease: "linear"
      });
    }
});



