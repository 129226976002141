import { getRequest } from "../util/util";

const storiesURL = (skip=0,take=9) => `${location.origin}/umbraco/api/Stories/GetStoriesList?skip=${skip}&take=${take}`;

export async function loadMoreStories(e){
  const storiesWrapper = document.querySelector('.js--stories-wrapper');
  e.preventDefault();
 /* console.log(e.currentTarget);*/
  const query = document.querySelectorAll(".js--storyDetailsPage");
  if (!query.length) return;
  const storiesShown = (query) => Array.from(query).length;
  //console.log(storiesShown(query));
  let stories = await getRequest(storiesURL(storiesShown(query),9));
  if(stories.length!=0){
    const frag = document.createDocumentFragment();
    stories.forEach(element => {
      const {title,description,imageDesktop,imageMobile,url} = element;
      console.log(title,description,imageDesktop,imageMobile,url);
      const temp = document.createElement('div');
      const elem = `
      <div class="js--storyDetailsPage flex flex-col gap-4">
        <picture>
          ${imageMobile!=null ? `
          <source srcset="${imageMobile.url}" media="(max-width=800px)" width="${imageMobile.width}" height="${imageMobile.height}" />
          `:""}
          
          <img class="rounded-[var(--radius-md)] mb-8" src="${imageDesktop.url}" width=${imageDesktop.width}" height="${imageDesktop.height}" />
        </picture>
        <h5 class="heading-5">${title}</h5>
        <p class="lg">${description}</p>
        <a class="text-link self-start" href="${url}">READ MORE</a>
      </div>
      `

      temp.innerHTML = elem;
      Array.from(temp.children).forEach(item => frag.appendChild(item));

    });
    storiesWrapper.appendChild(frag);
  }
  // console.log("### Inside stories Page js",stories);
}

