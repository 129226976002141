﻿let inputLeft = document.getElementById("input-left");
let inputRight = document.getElementById("input-right");
const $inputLeftElements = document.querySelectorAll(".input-left");
const $inputRightElements = document.querySelectorAll(".input-right");
let range = document.querySelector(".slider > .range");
let priceFrom = document.querySelector(".price-from");
let priceTo = document.querySelector(".price-to");

$inputLeftElements.forEach(function (element) {
    element.addEventListener("input", function (e) {
        var _this = e.currentTarget;
        let min = parseInt(_this.min);
        let max = parseInt(_this.max);
        let step = parseInt(_this.step);
        let value = parseInt(_this.value);
        let guid = _this.dataset.id;
        let category = _this.dataset.category;


        var inputRightElement = document.getElementById("input-right-" + guid);
        var rangeElement = document.getElementById("range-" + guid);
        var fromElement = document.getElementById("input-left-span-" + guid);

        var inputRightElementValue = parseInt(inputRightElement.value);

        _this.value = Math.min(value, inputRightElementValue - step);

        fromElement.textContent = _this.value + "-";

        let percent = ((_this.value - min) / (max - min)) * 100;

        rangeElement.style.left = percent + "%";

        if (category != "") {
            let categoryButton = category + '-tab-button';
            document.getElementById(categoryButton).disabled = false;
        }

    });
   
    element.addEventListener("mouseover", (e) => {
        e.currentTarget.classList.add("hover");
    });
    element.addEventListener("mouseout", (e) => {
        e.currentTarget.classList.remove("hover");
    });
    element.addEventListener("mousedown", (e) => {
        e.currentTarget.classList.add("active");
    });
    element.addEventListener("mouseup", (e) => {
        e.currentTarget.classList.remove("active");
    });
    element.addEventListener("touchstart", (e) => {
        e.currentTarget.classList.add("active");
    });
    element.addEventListener("touchend", (e) => {
        e.currentTarget.classList.remove("active");
    });
    element.dispatchEvent(new Event('input', { bubbles: true}));
});

$inputRightElements.forEach(function (element) {
    element.addEventListener("input", function (e) {
        var _this = e.currentTarget;
        let min = parseInt(_this.min);
        let max = parseInt(_this.max);
        let step = parseInt(_this.step);
        let value = parseInt(_this.value);
        let guid = _this.dataset.id;
        let category = _this.dataset.category;

        var inputLeftElement = document.getElementById("input-left-" + guid);
        var rangeElement = document.getElementById("range-" + guid);
        var inputLeftElementValue = parseInt(inputLeftElement.value);
        var toElement = document.getElementById("input-right-span-" + guid);

        _this.value = Math.max(value, inputLeftElementValue + step);
        toElement.textContent = _this.value;
        let percent = ((_this.value - min) / (max - min)) * 100;
        rangeElement.style.right = 100 - percent + "%";

        if (category != "") {
            let categoryButton = category + '-tab-button';
            document.getElementById(categoryButton).disabled = false;
        }
      
    });
    element.dispatchEvent(new Event('input', { bubbles: true }));

    element.addEventListener("mouseover", (e) => {
        e.currentTarget.classList.add("hover");
    });
    element.addEventListener("mouseout", (e) => {
        e.currentTarget.classList.remove("hover");
    });
    element.addEventListener("mousedown", (e) => {
        e.currentTarget.classList.add("active");
    });
    element.addEventListener("mouseup", (e) => {
        e.currentTarget.classList.remove("active");
    });
    element.addEventListener("touchstart", (e) => {
        e.currentTarget.classList.add("active");
    });
    element.addEventListener("touchend", (e) => {
        e.currentTarget.classList.remove("active");
    });
    element.dispatchEvent(new Event('input', { bubbles: true }));

});


//function setLeftValue() {
//    let _this = inputLeft,
//        min = parseInt(_this.min),
//        max = parseInt(_this.max);
//    step = parseInt(_this.step);

//    _this.value = Math.min(
//        parseInt(_this.value),
//        parseInt(inputRight.value) - step
//    );
// /*   priceFrom.textContent = `from :${_this.value * 1000}`;*/

//    let percent = ((_this.value - min) / (max - min)) * 100;

//    range.style.left = percent + "%";
//}

//setLeftValue();

//function setRightValue() {
//    let _this = inputRight,
//        min = parseInt(_this.min),
//        max = parseInt(_this.max);
//    step = parseInt(_this.step);


//    _this.value = Math.max(parseInt(_this.value), parseInt(inputLeft.value) + step);
//  /*  priceTo.textContent = `to: ${_this.value * 1000}`;*/

//    let percent = ((_this.value - min) / (max - min)) * 100;

//    range.style.right = 100 - percent + "%";
//}

//setRightValue();

//inputLeft.addEventListener("input", setLeftValue);
//inputRight.addEventListener("input", setRightValue);

//inputLeft.addEventListener("mouseover", (e) => {
//    inputLeft.classList.add("hover");
//});
//inputLeft.addEventListener("mouseout", (e) => {
//    inputLeft.classList.remove("hover");
//});
//inputLeft.addEventListener("mousedown", (e) => {
//    inputLeft.classList.add("active");
//});
//inputLeft.addEventListener("mouseup", (e) => {
//    inputLeft.classList.remove("active");
//});
//inputLeft.addEventListener("touchstart", (e) => {
//    inputLeft.classList.add("active");
//});
//inputLeft.addEventListener("touchend", (e) => {
//    inputLeft.classList.remove("active");
//});

//inputRight.addEventListener("mouseover", (e) => {
//    inputRight.classList.add("hover");
//});
//inputRight.addEventListener("mouseout", (e) => {
//    inputRight.classList.remove("hover");
//});
//inputRight.addEventListener("mousedown", (e) => {
//    inputRight.classList.add("active");
//});
//inputRight.addEventListener("mouseup", (e) => {
//    inputRight.classList.remove("active");
//});
//inputRight.addEventListener("touchstart", (e) => {
//    inputRight.classList.add("active");
//});
//inputRight.addEventListener("touchend", (e) => {
//    inputRight.classList.remove("active");
//});
