﻿import { Tabs } from "flowbite";

const stickyNavContainer = document.querySelector("#sticky-nav-container");
var navbar = document.querySelector("#main-navbar");
const mastWrapper = document.getElementById("masthead-wrapper");

//move stickynavCo
if (stickyNavContainer != null) {
  navbar.parentNode.insertBefore(stickyNavContainer, navbar.nextSibling);
}

const tabsElement = document.getElementById("boats-tab-parent");

export function setLightTheme(elem) {
  if (elem.classList.contains("theme-light")) return;
  elem.classList.remove("theme-dark");
  elem.classList.add("theme-light");
}
export function setDarkTheme(elem) {
  if (elem.classList.contains("theme-dark")) return;
  elem.classList.remove("theme-light");
  elem.classList.add("theme-dark");
}

export function setTheme(theme, elem) {
  theme == "theme-light" ? setLightTheme(elem) : setDarkTheme(elem);
}


if (tabsElement != null) {
  var stickyTabLinks = tabsElement.getElementsByClassName("tab-link");
  //console.log(stickyTabLinks);

  // create an array of objects with the id, trigger element (eg. button), and the content element
  var tabElements = [];
  Array.from(stickyTabLinks).forEach(function (link) {
    tabElements.push({
      id: link.textContent.toLowerCase(),
      triggerEl: document.querySelector(
        "#" + link.textContent.toLowerCase() + "-tab"
      ),
      targetEl: document.querySelector(
        "#" + link.textContent.toLowerCase() + "-tab-content"
      ),
    });
  });
  // console.log(tabElements);

  // options with default values
  const options = {
    defaultTabId:
      stickyTabLinks.length > 0 ? stickyTabLinks[0].id : "overview-tab",
    activeClasses: "active",
    inactiveClasses: "inactive",   
  };

  // instance options with default values
  const instanceOptions = {
    id:
      tabElements.length > 0
        ? stickyTabLinks[0].parentElement.parentElement.id
        : "boats-tab-parent",
    override: true,
  };

  const tabs = new Tabs(tabsElement, tabElements, options, instanceOptions);
  const initialMastHeadTheme = mastWrapper.classList.contains("theme-light")
    ? "theme-light"
    : "theme-dark";
  
  
  tabs.updateOnShow(() => {
    if (tabs.getActiveTab().id == "specs") {
      setDarkTheme(mastWrapper);
    } else if (tabs.getActiveTab().id == "compare") {
      setLightTheme(mastWrapper);
    } else {
      setTheme(initialMastHeadTheme,mastWrapper);
    }

    window.scroll({top:0, behavior:"instant"})
    // console.log("#CC 64 theme", mastWrapper.classList);
  });

  const viewSpecBtn = document.querySelector(".js--boatDetailSpecsRow-spec");
  if (viewSpecBtn != null) {
    viewSpecBtn.addEventListener("click", () => {
      tabs.show("specs");
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "instant",
      });
    });
  }
}
