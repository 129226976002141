import { gsap } from "gsap";

const elem = document.querySelectorAll(
  '[data-alias-name="scrollingHeroBannerRow"]'
);

scrollingRow(elem);
document.addEventListener("DOMContentLoaded", () => scrollingRow(elem));

function scrollingRow(elem) {
  if (elem == null) return;
  elem.forEach((item) => {
    const { aliasName, uniqueId, componentTheme } = item.dataset;
    const isLight = componentTheme === "theme-light";
    const videoDarken = new gsap.timeline({
      scrollTrigger: {
        trigger: `#${aliasName}-${uniqueId}`,
        start: "top top",
        end: "95% bottom",
        scrub: 0,
        markers: false,
      },
    });

    videoDarken.to(`#${aliasName}-${uniqueId}`, {
      backgroundColor: isLight ? "rgba(233,232,230,0.8)" : "rgba(17,17,17,0.8)",
      ease: "linear",
    });
  });
}
