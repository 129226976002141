export async function getRequest(url){
  try{
    const response = await fetch(url);
    if(response.status === 200) {
    const data = await response.json();
      // console.log("Success:",response,data);
      return data;
    } else{
      console.log("Server Error",response)
    }
  } catch(error){
    console.log("Error", error);
  }
}