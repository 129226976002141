
export function updateCategory(elemId){
  const elem = document.getElementById(elemId);
  if (elem==null) return;
  const tabs = elem.querySelectorAll('.js--tab-link');
  const categorySections = Array.from(document.querySelectorAll('section[data-category]'));
  tabs.forEach((tab,i) => {
    const category = tab.textContent.trim().toLowerCase().replace(/\s/g,"");
    tab.addEventListener('click',()=>{
      tabs.forEach(tab=> tab.classList.remove('active'));
      tab.classList.add('active');
      if (category == "all") {
        categorySections.forEach(item => item.ariaHidden = "false");
      } else{
        categorySections.forEach(item => item.ariaHidden = "true")
        let filterCategory = categorySections.filter(item => item.dataset.category == category);
        filterCategory[0].ariaHidden = "false";
      }

    })
  });
}

updateCategory("knowledgeCenter__tabWapper");

export function handleLoadMore(e){
  const self = e.currentTarget;
  const section = self.closest('section');
  const next = 10;
  /*console.log("#CC31",{section})*/
  let hiddenArticles = Array.from(section.querySelectorAll('article[aria-hidden="true"]'));
  let len = hiddenArticles.length;
  if(len==0) {
    self.disabled = "true";
    return;
  } 
  if(len>next){
    for (let i=0; i<next; i++){
      hiddenArticles[i].ariaHidden = "false";
    }
  } else {
    hiddenArticles.forEach(item => item.ariaHidden = "false");
    self.disabled = "true";
  }
  }

