window.onload = () => {
    const tabSelected = new URLSearchParams(window.location.search).get('tab');
    updateSelectedTab(tabSelected);
    const link = document.querySelector('a[href="?tab=features-tab"]');
    if (link) {
        link.addEventListener('click', handleLinkClick, { once: true });
    }
};
function handleLinkClick(event) {
    event.preventDefault();
    updateSelectedTab('features-tab');
}
export function updateSelectedTab(tabSelected) {
    if (!tabSelected) {
        return;
    }
    const selectedTabElement = document.querySelector(`[data-tab-title="${tabSelected}"]`);
    if (selectedTabElement) {
        selectedTabElement.click();
    }
}
