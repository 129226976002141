﻿import { gsap } from "gsap";
const $slideInContent = document.querySelectorAll(".slide-in-content");

$slideInContent.forEach((element) => {
    const slideInElement = new gsap.timeline({
        scrollTrigger: {
            trigger: element,
            start: "top 90%",
            end: "top 50%",
            scrub: 1,
            markers: false
        }
    });

    slideInElement.from(element, {
        opacity: 0,
        yPercent: 10,
        ease: "ease-out"
    });
});


