const is_ios = /iP(ad|od|hone)/i.test(window.navigator.userAgent);
const is_safari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
const iOS = is_ios && is_safari;
const $handle = document.createElement("div");
const $track = document.createElement("div");

let elem = document.querySelectorAll("[data-overflow-scrollgallary]");
createScroll(elem);

document.addEventListener("DOMContentLoaded", function () {
  elem = document.querySelectorAll("[data-overflow-scrollgallary]");
  createScroll(elem);
});
document.addEventListener("load", function () {
  elem = document.querySelectorAll("[data-overflow-scrollgallary]");
  createScroll(elem);
});
/**
 *
 * @param {HTMLElement[]} overflowElem
 * @returns
 */
function createScroll(overflowElem) {
  if (overflowElem == null) return;

  overflowElem.forEach((item) => {
    const { uniqueId } = item.dataset;
    const $overflowWrapper = item.querySelector(
      `#overflow-wrapper-${uniqueId}`
    );
    const $overflowScroll = item.querySelector(`#overflow-scroll-${uniqueId}`);
    const $cards = item.querySelector(".storyCardsGalleryItem");
    const $prevButton = item.querySelector(`#previous-${uniqueId}`);
    const $nextButton = item.querySelector(`#next-${uniqueId}`);

    // number of cards to scroll
    let scrollDistance = 1;

    if (iOS) {
      //console.log("Is iOS device");

      $handle.className = "handle";
      $handle.id = `handle-${uniqueId}`;
      $track.className = "track";
      $track.id = `track-${uniqueId}`;

      $overflowWrapper.append($handle);
      $overflowWrapper.append($track);
    }

    let wrapperWidth, scrollerWidth, contentWidth, handleWidth, cardWidth;

    function setScrollerDimensions() {
      const clone = $overflowWrapper.cloneNode(true);
      clone.id = "clone-" + $overflowWrapper.id;
      //console.log("#CC clone", clone);
      clone.querySelectorAll("img").forEach((item) => (item.src = ""));
      document.body.appendChild(clone);
      wrapperWidth = clone.offsetWidth;
      cardWidth = clone.querySelector(".storyCardsGalleryItem").offsetWidth;
      scrollDistance = Math.floor(wrapperWidth / cardWidth) * cardWidth;
      scrollerWidth = clone.offsetWidth;
      contentWidth = clone.querySelector(
        `#overflow-scroll-${uniqueId}`
      ).scrollWidth;
      clone.remove();
      // wrapperWidth = $overflowWrapper.offsetWidth;
      // cardWidth = $cards.offsetWidth;
      // scrollDistance = Math.floor(wrapperWidth / cardWidth) * cardWidth;
      // // width of the scrolling container
      // scrollerWidth = $overflowScroll.offsetWidth;
      // // width of the contents in the scroller
      // contentWidth = $overflowScroll.scrollWidth;

      toggleButtons();

      if (iOS) {
        // set dimensions/positioning for the handle a
        handleWidth = scrollerWidth / contentWidth;

        $track.style.width = wrapperWidth + "px";
        $handle.style.width = handleWidth * 100 + "%";
      }
    }

    function toggleButtons() {
      if ($overflowScroll.scrollLeft > 0) {
        $prevButton.classList.add("active");
      } else {
        $prevButton.classList.remove("active");
      }

      if (
        Math.ceil($overflowScroll.scrollLeft) <
        contentWidth - scrollerWidth
      ) {
        $nextButton.classList.add("active");
      } else {
        $nextButton.classList.remove("active");
      }
    }

    setScrollerDimensions();

    $nextButton.addEventListener("click", () => {
      $overflowScroll.scrollLeft += scrollDistance;
    });

    $prevButton.addEventListener("click", () => {
      $overflowScroll.scrollLeft -= scrollDistance;
    });

    window.addEventListener("resize", setScrollerDimensions);

    $overflowScroll.addEventListener("scroll", () => {
      toggleButtons();

      if (iOS) {
        const handlePosition = $overflowScroll.scrollLeft / contentWidth;
        $handle.style.left = handlePosition * 100 + "%";
      }
    });
  });
}
