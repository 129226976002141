﻿import { gsap } from "gsap";

const $slideUpDarken = document.querySelectorAll(".slide-up-darken");

let darkenOpacity = 1;
let darkenRGB = "17,17,17";
let scrollStart = "top 50%";

$slideUpDarken.forEach((element) => {
  if (element.dataset.opacity) {
    darkenOpacity = element.dataset.opacity;
  }

  if (element.dataset.rgb) {
    darkenRGB = element.dataset.rgb;
  }

  if (element.dataset.scrollStart) {
    scrollStart = element.dataset.scrollStart;
  }

  const slideUpDarken = new gsap.timeline({
    scrollTrigger: {
      trigger: element,
      start: scrollStart,
      end: "bottom bottom",
      scrub: 1,
      markers: false,
    },
  });

  slideUpDarken.to(element, {
    backgroundColor: "rgba(" + darkenRGB + "," + darkenOpacity + ")",
    ease: "linear",
  });
});
