export function updateNavLink(elemId){
  const elem = document.getElementById(elemId);
  if (elem==null) return;
  const tabs = elem.querySelectorAll('.js--tab-link');
  tabs.forEach((tab,i) => {
    tab.addEventListener('click',()=>{
      tabs.forEach(tab=> tab.classList.remove('active'));
      tab.classList.add('active');
    })
  });
 }

 updateNavLink("artofBoatBuildingPg__tabWapper");
 updateNavLink("eventPg__tabWrapper");
