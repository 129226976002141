﻿import { Tabs } from 'flowbite';
import _ from "underscore";


const tabsElement = document.getElementById('quiz-tab-parent');
const ALLBoatsURL = `${location.origin}/umbraco/api/boats/GetAllBoatsForBoatQuiz`;
import { getRequest } from "../util/util.js";

if (tabsElement != null) {

    var stickyTabLinks = document.getElementsByClassName("tab-link");

    var finderOptions = {
        activities: new Array(),
        crewSize: '',
        bowType: '',
        boatLengths: new Array(),
        pricing: ''
    };

    var selectedValuesList = [];
    var boatIDsByFinderOptions = new Array();

    // const allBoats = await getRequest(ALLBoatsURL);
    // console.log(allBoats);

    // create an array of objects with the id, trigger element (eg. button), and the content element
    var tabElements = [];
    var categoryList = [];
    _.each(stickyTabLinks, function (link) {
        let tabName = link.textContent.toLowerCase();
        let triggerEl = '#' + tabName + '-tab';
        let targetEl = '#' + tabName + '-tab-content'
        tabElements.push({
            id: tabName,
            triggerEl: document.querySelector(triggerEl),
            targetEl: document.querySelector(targetEl),
        })
        categoryList.push(tabName);
    });

    // options with default values
    const options = {
        defaultTabId: stickyTabLinks.length > 0 ? stickyTabLinks[0].id.toLowerCase() : "activities-tab",
        activeClasses:
            'active',
        inactiveClasses:
            'inactive',
        onShow: () => {
            //console.log('tab is shown');
        },
    };

    // instance options with default values
    const instanceOptions = {
        id: tabElements.length > 0 ? stickyTabLinks[0].parentElement.parentElement.id : 'quiz-tab-parent',
        override: true
    };

    const tabs = new Tabs(tabsElement, tabElements, options, instanceOptions);

    const $questionMultipleChoiceCards = document.querySelectorAll(".question-choice-card");

    $questionMultipleChoiceCards.forEach(function (element) {
        element.addEventListener("click", function (e) {
            var questionCardClassList = e.currentTarget.classList;
            questionCardClassList.toggle("selected");
            var questionCardParent = e.currentTarget.parentElement;
            var questionCardCategory = e.currentTarget.dataset.category;
            var questionCardText = e.currentTarget.dataset.text;
            var questionCardValue = e.currentTarget.dataset.value;

            checkSelectedOptionsInTab(questionCardCategory, questionCardParent)

            e.preventDefault();
        });
    });


    const $questionSingleChoiceCards = document.querySelectorAll(".question-choice-single-card");

    $questionSingleChoiceCards.forEach(function (element) {
        element.addEventListener("click", function (e) {
            var questionCardClassList = e.currentTarget.classList;
            var allChoices = document.querySelectorAll(".question-choice-single-card");
            allChoices.forEach(function (element) {
                if (element == e.currentTarget) {
                    if (element.classList.contains("selected")) {
                        element.classList.toggle("selected");
                    }
                    else {
                        element.classList.add("selected");
                    }
                }
                else {
                    element.classList.remove("selected");

                }
            })
            var questionCardParent = e.currentTarget.parentElement;
            var questionCardCategory = e.currentTarget.dataset.category;
            var questionCardText = e.currentTarget.dataset.text;
            var questionCardValue = e.currentTarget.dataset.value;
            checkSelectedOptionsInTab(questionCardCategory, questionCardParent)
            e.preventDefault();
        });
    });

    /**
        * Used to enable button to move to next tab if an option is selected
        * @param {string} category
        * @param {HTMLDivElement} questionCardParent
     */
    function checkSelectedOptionsInTab(category, questionCardParent) {
        var currentTabId = category + '-tab-content';

        var curentTabButton = category + '-tab-button';

        var currentTab = document.getElementById(currentTabId);

        var questionType = questionCardParent.className;

        if (questionType == "multiple-question-choice-card-container" || questionType == "single-question-choice-card-container") {

            var childElements = questionCardParent.children;
            var oneSelected = false;

            for (var child of childElements) {
                if (child.classList.contains("selected")) {
                    oneSelected = true;
                    break
                }

            }

            if (oneSelected) {
                document.getElementById(curentTabButton).disabled = false;
            }
            else {
                document.getElementById(curentTabButton).disabled = true;

            }
        }
        else if (questionType == "single-choice-button-card-container") {
            var childElements = questionCardParent.children;
            var oneSelected = false;
            for (var child of childElements) {
                if (child.classList.contains("active")) {
                    oneSelected = true;
                    break
                }

            }
            if (oneSelected) {
                document.getElementById(curentTabButton).disabled = false;
            }
            else {
                document.getElementById(curentTabButton).disabled = true;

            }
        }

    }

    const $singleButtonChoices = document.querySelectorAll(".single-button-choice");

    $singleButtonChoices.forEach(function (element) {
        element.addEventListener("click", function (e) {
            var questionCardParent = e.currentTarget.parentElement;
            var questionCardCategory = e.currentTarget.dataset.category;
            var questionCardText = e.currentTarget.dataset.text;
            var questionCardValue = e.currentTarget.dataset.value;
            var questionCardImageURL = e.currentTarget.dataset.imageUrl;
            var questionCardImageAltText = e.currentTarget.dataset.imageAlttext;
            var questionCardImageDescription = e.currentTarget.dataset.imageDescription;

            document.getElementById("single-choice-button-card-image").src = questionCardImageURL;
            document.getElementById("single-choice-button-card-image").alt = questionCardImageAltText;
            document.getElementById("single-choice-button-card-image-description").innerHTML = questionCardImageDescription;
            var buttonList = document.querySelectorAll(".single-button-choice");
            buttonList.forEach(function (button) {
                if (button == e.currentTarget) {
                    if (button.classList.contains("active")) {
                        button.classList.toggle("active");
                        document.getElementById("single-choice-button-card-image").src = document.getElementById("single-choice-button-card-image").dataset.defaultUrl;
                        document.getElementById("single-choice-button-card-image-description").innerHTML = "";
                    }
                    else {
                        button.classList.add("active");
                    }
                }
                else {
                    button.classList.remove("active");

                }
            })

            checkSelectedOptionsInTab(questionCardCategory, questionCardParent)

            e.preventDefault();
        });
    });

    const $questionButtons = document.querySelectorAll(".question-card-button");

    $questionButtons.forEach(function (element) {
        element.addEventListener("click", function (e) {
            e.preventDefault();
            var buttonCategory = e.currentTarget.dataset.category;
            moveToNextTab(buttonCategory);
        });
    });

    async function gotoResults(selectedValues) {
       /* console.log(selectedValues);*/
        var finalResults = [];
        var finderResultsUrl = '/boat-finder/results?';
        var finderUrlParameters = '';
        var boatsFinderByOptions = await getRequest(ALLBoatsURL);
        var boatsLeft = "";
        if (selectedValuesList.length > 0) {
            Array.prototype.forEach.call(selectedValuesList, function (element) {
                var category = element.category;
                if (category == "activities") {
                    boatsFinderByOptions = boatsFinderByOptions.filter(function (boat) {

                        var boatActivities = new Set(boat.activitiesList);

                        const commonItems = element.selected.filter(function (item) {
                            return boatActivities.has(item.toUpperCase())
                        });

                        return commonItems.length > 0;
                        //return boat.activitiesList.some(function (item) {
                        //    return element.selected.includes(item);
                        //})
                    })
                }
                if (category == "crew") {
                    boatsFinderByOptions = boatsFinderByOptions.filter(function (boat) {
                        var boatCrewSize = boat.seatingRange;
                        var boatMin = boatCrewSize.split("-")[0];
                        var boatMax = boatCrewSize.split("-")[1];

                        console.log(boatMin)
                        console.log(boatMax)


                        //if ((parseInt(boatMin) >= parseInt(element.selected.minValue)) && (parseInt(boatMax) <= parseInt(element.selected.maxValue))) {
                        //    return true;
                        //}

                        if ((parseInt(boatMin) >= parseInt(element.selected.minValue))) {
                            return true;
                        }
                    })
                }
                if (category == "bow") {

                    if (!element.selected.includes("No Preference")) {
                        boatsFinderByOptions = boatsFinderByOptions.filter(function (boat) {
                            return element.selected.includes(boat.bowTypes);
                        })
                    }
                }
                if (category == "lifestyle") {

                    boatsFinderByOptions = boatsFinderByOptions.filter(function (boat) {
                        return element.selected.includes(boat.lifestyle);
                    })
                }
                if (category == "length") {
                    boatsFinderByOptions = boatsFinderByOptions.filter(function (boat) {
                        var boatCrewSize = boat.boatSizeRange;
                        var boatMin = boatCrewSize.split("-")[0];
                        var boatMax = boatCrewSize.split("-")[1];

                        if ((parseInt(boatMin) >= parseInt(element.selected.minValue)) && (parseInt(boatMax) <= parseInt(element.selected.maxValue))) {
                            return true;
                        }
                    })
                }

            });
            finalResults = boatsFinderByOptions;
            _.each(finalResults, function (boat, i) {
                if (i < finalResults.length - 1) {
                    finderUrlParameters += 'id=' + boat.id + '&';
                }
                else {
                    finderUrlParameters += 'id=' + boat.id;
                }
            });
            finderResultsUrl = finderResultsUrl + finderUrlParameters;
            window.location = finderResultsUrl;
        }
    }

    /**
        * Used to move to the next tab on button Click
        * @param {string} category
     */
    function moveToNextTab(category) {
        let tabName = category;
        var currentIndex = _.indexOf(categoryList, tabName);
        updateFinderSelectedOptions(category);
        updateBoatResultButton();
        if (currentIndex != -1) {
            var nextTabIndex = currentIndex + 1;
            if (nextTabIndex >= categoryList.length) {
                gotoResults(selectedValuesList);
            }
            else {
                var nextCategory = categoryList[nextTabIndex];
                let triggerEl = nextCategory + '-tab';
                document.getElementById(triggerEl).classList.remove("disabled");
                tabs.show(nextCategory);
            }


        }

    }

    /**
    * Update the selected values into a object
    * @param {string} category
 */
    function updateFinderSelectedOptions(category) {
        let tabName = category;
        var currentTabId = category + '-tab-content';
        var curentTabButtonId = category + '-tab-button';

        var $currentTabButton = document.getElementById(curentTabButtonId);
        var $currentTab = document.getElementById(currentTabId);
        if ($currentTab.getElementsByClassName("multiple-question-choice-card-container").length > 0) {
            var $multipleChoiceContainer = $currentTab.getElementsByClassName("multiple-question-choice-card-container")[0];

            var $selectedChoices = $multipleChoiceContainer.getElementsByClassName("selected");
            var selectedValues = [];
            Array.prototype.forEach.call($selectedChoices, function (element) {
                var selectedValue = element.dataset.value;
                selectedValues.push(selectedValue);
            });
            var resultList = {
                category: tabName,
                selected: selectedValues
            };
            updateFinderList(resultList, selectedValuesList);
        }
        if ($currentTab.getElementsByClassName("question-dual-slider-card-container").length > 0) {
            var $dualChoiceContainer = $currentTab.getElementsByClassName("question-dual-slider-card-container")[0];
            var $sliderContainer = $dualChoiceContainer.getElementsByClassName("multi-range-slider")[0];
            var $inputElements = $sliderContainer.getElementsByTagName("input");
            if ($inputElements.length > 0) {
                var selectedSliderValue = {
                    minValue: '',
                    maxValue: ''
                };
                Array.prototype.forEach.call($inputElements, function (element) {
                    var selected = element.value;
                    if (element.classList.contains("input-left")) {
                        selectedSliderValue.minValue = selected;
                    }
                    else {
                        selectedSliderValue.maxValue = selected;

                    }
                });
                var resultList = {
                    category: tabName,
                    selected: selectedSliderValue
                };

                updateFinderList(resultList, selectedValuesList);
            }

        }
        if ($currentTab.getElementsByClassName("single-question-choice-card-container").length > 0) {
            var $singleChoiceContainer = $currentTab.getElementsByClassName("single-question-choice-card-container")[0];
            var $selectedChoices = $singleChoiceContainer.getElementsByClassName("selected");
            var selectedValues = [];
            Array.prototype.forEach.call($selectedChoices, function (element) {
                var selectedValue = element.dataset.value;
                selectedValues.push(selectedValue);
            });

            var resultList = {
                category: tabName,
                selected: selectedValues
            };
            updateFinderList(resultList, selectedValuesList);
        }
        if ($currentTab.getElementsByClassName("single-choice-button-card-container").length > 0) {
            var $singleButtonChoiceContainer = $currentTab.getElementsByClassName("single-choice-button-card-container")[0];

            var $selectedChoices = $singleButtonChoiceContainer.getElementsByClassName("active");
            var selectedValues = [];
            Array.prototype.forEach.call($selectedChoices, function (element) {
                var selectedValue = element.dataset.value;
                selectedValues.push(selectedValue);
            });
            var resultList = {
                category: tabName,
                selected: selectedValues
            };
            updateFinderList(resultList, selectedValuesList);
        }
    }

    /**
    * check if the selected value final list has the selected values by category. If found update the values else add it
    * @param {object} option
    * @param {array} selectedList
 */
    function updateFinderList(option, selectedList) {
        if (selectedList.length > 0) {
            var itemIndex = selectedList.findIndex((elm) => elm.category === option.category);
            if (itemIndex > -1) {
                selectedList[itemIndex].selected = option.selected;
            }
            else {
                selectedList.push(option);
            }
        }
        else {
            selectedList.push(option)
        }
    }

    /**
* check if the selected value final list has the selected values by category. If found update the values else add it
*/
  async  function updateBoatResultButton() {
        var $buttonElement = document.getElementById("results-tab-button");
      var boatsFinderByOptions = await getRequest(ALLBoatsURL);
      /*console.log(boatsFinderByOptions)*/
        var boatsLeft = "";
        if (selectedValuesList.length > 0) {
            Array.prototype.forEach.call(selectedValuesList, function (element) {
                var category = element.category;
                if (category == "activities") {
                    boatsFinderByOptions = boatsFinderByOptions.filter(function (boat) {

                        var boatActivities = new Set(boat.activitiesList);

                        const commonItems = element.selected.filter(function (item) {
                            return boatActivities.has(item.toUpperCase())
                        });

                        return commonItems.length > 0;
                        //return boat.activitiesList.some(function (item) {
                        //    return element.selected.includes(item);
                        //})
                    })
                }
                if (category == "crew") {
                    boatsFinderByOptions = boatsFinderByOptions.filter(function (boat) {
                        var boatCrewSize = boat.seatingRange;
                        var boatMin = boatCrewSize.split("-")[0];
                        var boatMax = boatCrewSize.split("-")[1];

                        //if ((parseInt(boatMin) > parseInt(element.selected.minValue)) && (parseInt(boatMax) <= parseInt(element.selected.maxValue))) {
                        //    return true;
                        //}

                        if ((parseInt(boatMin) > parseInt(element.selected.minValue))) {
                            return true;
                        }
                    })
                }
                if (category == "bow") {

                    if (!element.selected.includes("No Preference")) {
                        boatsFinderByOptions = boatsFinderByOptions.filter(function (boat) {
                            return element.selected.includes(boat.bowTypes);
                        })
                    }
                }
                if (category == "lifestyle") {

                    boatsFinderByOptions = boatsFinderByOptions.filter(function (boat) {
                        return element.selected.includes(boat.lifestyle);
                    })
                }
                if (category == "length") {
                    boatsFinderByOptions = boatsFinderByOptions.filter(function (boat) {
                        var boatCrewSize = boat.boatSizeRange;
                        var boatMin = boatCrewSize.split("-")[0];
                        var boatMax = boatCrewSize.split("-")[1];

                        if ((parseInt(boatMin) > parseInt(element.selected.minValue)) && (parseInt(boatMax) <= parseInt(element.selected.maxValue))) {
                            return true;
                        }
                    })
                }
                
            });
            console.log(boatsFinderByOptions);

            $buttonElement.innerHTML = boatsFinderByOptions.length + " results";

        }
    }


}