﻿import $ from "jquery";

//#region Dealer Iframe Logic
if (window.location.pathname.indexOf("dealer-locator") != -1) {
    $(function () {
        $('#dealer_locator_submit').click();
    });
    //$("#dealer-search-button").click(function () {
    //    var country = "";
    //    var zipcode = "";
    //    window.location.href = "/dealer-locator/?zipcode=" + zipcode + "&country=" + country;
    //});
    $(document).on('click', '#dealer-search-button', function () {
        var country = "";
        var zipcode = "";
        window.location.href = "/dealer-locator/?zipcode=" + zipcode + "&country=" + country;
    });

    $('#link_div').click(function () {
        $('#dealer_locator_submit').click();
    });
}

     //#endregion