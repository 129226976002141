import $ from "jquery";

function getUrlParameter(sParam) {
	var sPageURL = window.location.search.substring(1),
		sURLVariables = sPageURL.split('&'),
		sParameterName,
		i;

	for (i = 0; i < sURLVariables.length; i++) {
		sParameterName = sURLVariables[i].split('=');
		if (sParameterName[0] === sParam) {
			return typeof sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
		}
	}
	return false;
};
/*
Create cookie with expiry date
Ex: createCookie('ppkcookie', 'testcookie', 7)
*/
function createCookie(name, value, days) {
	if (days) {
		var date = new Date();
		date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
		var expires = "; expires=" + date.toGMTString();
	}
	else var expires = "";
	document.cookie = name + "=" + value + expires + "; path=/";
}

/*
reads cookie
Ex: readCookie('ppkcookie1')
*/
function readCookie(name) {
	var nameEQ = name + "=";
	var ca = document.cookie.split(';');
	for (var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) == ' ') c = c.substring(1, c.length);
		if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
	}
	return null;
}

/**
 * Delete exisiting cookie by changing expiry date
 * Ex: eraseCookie('ppkcookie')
 * @param {string} name Name of the cookie to delete.
 */
function eraseCookie(name) {
	createCookie(name, "", -1);
}

// Delete cookie
function deleteCookie(cname) {
	const d = new Date();
	d.setTime(d.getTime() + (24 * 60 * 60 * 1000));
	let expires = "expires=" + d.toUTCString();
	document.cookie = cname + "=;" + expires + ";path=/";
}

// Read cookie
function getCookie(cname) {
	let name = cname + "=";
	let decodedCookie = decodeURIComponent(document.cookie);
	let ca = decodedCookie.split(';');
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) == ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {
			return c.substring(name.length, c.length);
		}
	}
	return "";
}

/**
 * Create cookie with expiry
 *
 * @param {string} name Name of the cookie to create.
 * @param {string} value value for the cooie to create.
 * @param {number} days days to set expiry based on the current date.
 */
function createConsentCookie(name, value, days) {

	let domain = "";
	if (location.hostname === "localhost" || location.hostname === "127.0.0.1") {
		domain = "localhost";
	}
	else {
		domain = ".mastercraft.com";
	}

	var expires = "";
	if (days) {
		var date = new Date();
		date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
		expires = "; expires=" + date.toGMTString();
	}
	else expires = "";
	document.cookie = name + "=" + value + expires + "; path=/; domain= " + domain + "; secure; SameSite=none;";
}

/**
 *Accepts all user consent cookies
 *
 */
function addAllConsentCookies() {
	let consent = true;

	createConsentCookie('user_cookie_consent', consent, 30);
	createConsentCookie('user_essential_cookie_consent', consent, 30);
	createConsentCookie('user_performance_cookie_consent', consent, 30);
	createConsentCookie('user_functional_cookie_consent', consent, 30);
	createConsentCookie('user_social_media_cookie_consent', consent, 30);

	$('#performanceCookieSwitch').prop('checked', true);
	$('#functionalCookieSwitch').prop('checked', true);
	$('#socialmediaCookieSwitch').prop('checked', true);
	setGTMConsent();
	setEthiosConsent();
	hideCookieConsent();
	loadUTMParameters();
}

/**
 *Checks user consent on cookie switches and creates the appropriate consent cookies
 *
 */
function addCustomConsentCookies() {
	let consent = true;
	let userEssentialConsent = true;
	let performanceCookieSwitchConsent = $('#performanceCookieSwitch').is(':checked');
	let functionalCookieSwitchConsent = $('#functionalCookieSwitch').is(':checked');
	let socialmediaCookieSwitchConsent = $('#socialmediaCookieSwitch').is(':checked');

	createConsentCookie('user_cookie_consent', consent, 30);
	createConsentCookie('user_essential_cookie_consent', userEssentialConsent, 30);
	createConsentCookie('user_performance_cookie_consent', performanceCookieSwitchConsent, 30);
	createConsentCookie('user_functional_cookie_consent', functionalCookieSwitchConsent, 30);
	createConsentCookie('user_social_media_cookie_consent', socialmediaCookieSwitchConsent, 30);
	setGTMConsent();
	setEthiosConsent();
	hideCookieConsent();
	loadUTMParameters();


}

$(document).on('click', '#show-cookie-consent', function () {
	showCookieConsent();
});
$(document).on('click', '#cookie-modal-custom-accept', function () {
	addCustomConsentCookies();
});
$(document).on('click', '#cookie-modal-all-accept', function () {
	addAllConsentCookies();
});
$(document).on('click', '#cookie-banner-close-btn-US', function () {
	addAllConsentCookies();
});
$(document).on('click', '#cookie-accept-btn-intl', function () {
	addAllConsentCookies();
});



$(document).on('keypress', '.cookie-settings-switch', function () {
	var keycode = (event.keyCode ? event.keyCode : event.which);
	if (keycode == 13) {
		checkBoxToggle(this);
	}
	event.stopPropagation();
});

function checkBoxToggle(box) {

	var $box = $(box);
	$box.prop('checked', !$box.prop('checked'));

}

function showCookieConsent() {
	var cookieConsent = document.querySelector("#cookieBox");
	cookieConsent.classList.remove("opacity-0", "hidden");
	cookieConsent.classList.add("show");

};

function hideCookieConsent() {
	var cookieConsent = document.querySelector("#cookieBox");
	cookieConsent.classList.remove("show", "hidden");
	cookieConsent.classList.add("hidden");

};

/**
 *Check if any script exists with given URL as source
 *
 * @param {string} url Name of the parameter to create cookie.
 * @return {boolean} bool to determine if script exists
 */
function isScriptLoaded(url) {
	var scripts = document.getElementsByTagName('script');
	for (var i = scripts.length; i--;) {
		if (scripts[i].src == url) return true;
	}
	return false;
}

/**
 * Check if user accepeted Performance cookie consent and create UTM Parameter cookies
 *
 */
function loadUTMParameters() {
	var performanceConsent = getCookie("user_performance_cookie_consent");
	if (performanceConsent == "true") {

		createUTMCookie("utm_source");
		createUTMCookie("utm_term");
		createUTMCookie("utm_campaign");
		createUTMCookie("utm_medium");
		createUTMCookie("utm_content");
	}
}

/**
 * Create UTM Parameter Cookies.
 *
 * @param {string} UTMParameter Name of the parameter to create cookie.
 * @return {void} Creates cookie if value exists and if not erases existing value.
 */
function createUTMCookie(UTMParameter) {
	let parameterValue = getUrlParameter(UTMParameter);
	if (parameterValue != false && parameterValue != "") {
		createConsentCookie(UTMParameter, parameterValue, 1);
	}
	//else {
	//	this.eraseCookie(UTMParameter);
	//   }

}

//Set GTM consent if user accepted perfomance cookie consent.
function setGTMConsent() {
	var performanceConsent = getCookie("user_performance_cookie_consent");

	if (performanceConsent == "true") {
		let performanceCookieAcceptEvent = new Event('on_user_performance_cookie_consent_granted');
		document.dispatchEvent(performanceCookieAcceptEvent);

	}
	else {
		let performanceCookieDeclineEvent = new Event('on_user_performance_cookie_consent_withdrawn');
		document.dispatchEvent(performanceCookieDeclineEvent);
	}
};

//Set GTM consent if user accepted functional cookie consent.
function setEthiosConsent() {
	var functionalConsent = getCookie("user_functional_cookie_consent");

	if (functionalConsent == "true") {
		let functionalCookieAcceptEvent = new Event('on_user_functional_cookie_consent_granted');
		document.dispatchEvent(functionalCookieAcceptEvent);
	}
};

if (window.location.href.includes('CCPA')) {
	var elmnt = document.getElementById("CCPA");
	setTimeout(function () {
		elmnt.scrollIntoView();
	}, 2000);
}
window.loadUTMParameters = loadUTMParameters;
window.getCookie = getCookie;
window.createConsentCookie = createConsentCookie;
window.onload = loadUTMParameters();


