﻿import _ from "underscore";
function updateTheme () { 
  const elem = document.body.querySelector('[data-theme]');
  if (elem !=null) {
    let theme = elem.dataset.theme;
    if ( theme == "light"){
      $mastheadWrapper.classList.remove('theme-dark');
      $mastheadWrapper.classList.add(`theme-light`);
    }
  }
}
const $mastheadWrapper = document.getElementById("masthead-wrapper");
const $masthead = document.getElementById("masthead");

updateTheme();

// Variables to store the previous scroll position and state
let prevScrollPos = window.scrollY;
let scrollBelowMasthead = false;
let scrollingUp = false;
let mastheadFixed = false;
let mastheadSlideIn = false;
let mastheadHeight = $masthead.offsetHeight;


// debounced resize
var windowResize = _.debounce(() => {
    mastheadHeight = $masthead.offsetHeight;
}, 500);

// always calc the offset height // Note sho
window.addEventListener("resize", windowResize);

// Function to be throttled
var handleScroll = function () {
    // Your scroll logic here
    const currentScrollPos = window.scrollY;

    // Check if scroll position is greater than mastehead
    if (currentScrollPos >= 80) {
        if (!mastheadFixed) {
            $mastheadWrapper.classList.add("fixed");
            mastheadFixed = true;
        }
    } else {
        if (currentScrollPos <= 0) {
            $mastheadWrapper.classList.remove("fixed");
            $mastheadWrapper.classList.remove("slide-out");
            $mastheadWrapper.classList.remove("slide-in");
            mastheadFixed = false;
            mastheadSlideIn = false;
        }
    }

    // console.log("masthead  fixed", mastheadFixed);
    // console.log("currentScrollPos", currentScrollPos);

    // Check if scrolling up or down
    if (currentScrollPos < prevScrollPos) {
        // Scrolling up
        scrollingUp = true;
        if (mastheadFixed && !mastheadSlideIn) {
            //add the slide in class when scrolling up
            $mastheadWrapper.classList.remove("slide-out");
            $mastheadWrapper.classList.add("slide-in");
            mastheadSlideIn = true;
        }

    } else {
        scrollingUp = false;

        //console.log("mastheadSlideIn", mastheadSlideIn);
        //remove the slide in class when scrolling down
        if (mastheadFixed && mastheadSlideIn) {
            $mastheadWrapper.classList.remove("slide-in");
            $mastheadWrapper.classList.add("slide-out");
            mastheadSlideIn = false;
        }
    }

    // Update the previous scroll position
    prevScrollPos = currentScrollPos;
}

window.addEventListener("scroll", handleScroll);
