﻿import { gsap } from "gsap";
const $body = document.getElementById("body");
const $heroContent = document.querySelectorAll(".hero-image-row-intro");
//const $heroImage = document.querySelectorAll(".hero-image-bg");
//const $main = document.getElementById("main");
//const $blockList = document.querySelectorAll(".umb-block-list");


if ($heroContent.length > 0) {
    //add content section to body
    //$body.classList.add("hero-image-row-content");
    //console.log("test")
    //move hero image tag to bottom of main
//    $blockList[0].appendChild($heroImage[0]);
}

const featuresDarken = new gsap.timeline({
    scrollTrigger: {
        trigger: '.hero-image-row-intro',
        start: "top top",
        end: "800px top",
        scrub: 1,
        markers: false
    }
});

featuresDarken.to('.hero-image-bg', {
    opacity: 0,
    ease: "linear"
});

