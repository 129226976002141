updateBackground();
document.addEventListener('scroll', () => {
  updateBackground();
});


function updateBackground(){
  const $heroBanner = document.getElementById('hero-banner');
  if ($heroBanner==null) return;
  const nextElem = $heroBanner.nextElementSibling;
  const rect = nextElem.getBoundingClientRect();
  const media =  $heroBanner.querySelector('.hero-banner-image');
  if (rect.top < -20){
   media.style.visibility = "hidden";
  } else {
    media.style.visibility = "visible";
  }
}