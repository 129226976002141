﻿import $ from "jquery";


//#region Search Functionality
$("#search-input-button").click(function () {
    var skip = $(this).data("skip");
    var take = $(this).data("take");
    var search = $("#search-input").val();
    searchArticles(search, skip, take);
});

$('#search-input').keypress(function (e) {
    if (e.which == 13) {//Enter key pressed
        $('#search-input-button').click();//Trigger search button click event
    }
});

$(document).on('click', '.search-page-number', function () {
    var skip = $(this).data("skip");
    var take = $(this).data("take");
    var search = $("#header-search-input").val();
    searchArticles(search, skip, take);

});

$(document).on('click', '.search-page-next', function () {
    var skip = $(this).data("skip");
    var take = $(this).data("take");
    var search = $("#header-search-input").val();
    searchArticles(search, skip, take);

});

$(document).on('click', '.search-page-previous', function () {
    var skip = $(this).data("skip");
    var take = $(this).data("take");
    var search = $("#header-search-input").val();
    searchArticles(search, skip, take);

});

var searchArticles = function searchArticles(searchquery, skip, take) {
    //console.log(searchquery, skip, take);
    $.ajax({
        url: '/umbraco/api/Search/GetSearchResults',
        data: { "search": searchquery, "skip": skip, "take": take },
        type: "GET",
        dataType: 'json',
        success: function (data, textStatus, xhr) {
            //console.log(data);
            if (data.returnData.length > 0) {
                $("#search-results-div").empty();
                var totalresults = data.totalCount;
                var totalpages = data.pageCount;

                var ToAdd = "";
                $.each(data.returnData, function (i, obj) {
                    var Description = obj.description;
                    if (Description == null) {
                        Description = "";
                    }
                    var Title = obj.title;

                    ToAdd = "<div class='col-12 search-result-wrapper'>"
                        + "<div class='heading-2'>" + Title + "</div>"
                        + "<p class=''>" + Description + "</p>"
                        + "<a class='text-link' href=" + "'" + obj.url + "'" + " aria-label=" + "'continue reading" + Title + "'" + "> Continue Reading</a>"
                        + "</div>";
                    $(ToAdd).appendTo("#search-results-div");
                });

                //hides the link so that we dont have clicks
                //if (totalpages == 1) {
                //    $("#search-results-pagination-wrapper").hide();
                //}
                //else {
                //    $("#search-results-pagination-wrapper").empty();
                //    $("#search-results-pagination-wrapper").show();

                //    var previouspage = parseInt(skip) - parseInt(take);
                //    var nextpage = parseInt(skip) + parseInt(take);
                //    var currentPageNumber = 1;
                //    var previouspagenumber = currentPageNumber - 1;
                //    var nextpagenumber = currentPageNumber + 1;

                //    var pagesHtml = "";

                //    var currentpagehtml = "<button data-skip='" + data.Skip + "' data-take='" + data.Take + "' class='btn btn-transparent search-page-number search-active-page'>"
                //        + currentPageNumber
                //        + "</button>";
                //    var previouspagehtml = "<button data-skip='" + previouspage + "'data-take='" + data.Take + "' class='btn btn-transparent search-page-number'>"
                //        + previouspagenumber
                //        + "</button>";
                //    var nextPagegtml = "<button data-skip='" + nextpage + "'data-take='" + data.Take + "' class='btn btn-transparent search-page-number'>"
                //        + nextpagenumber
                //        + "</button>";

                //    var previousPageButton = "<button data-skip='" + previouspage + "'data-take='" + data.Take + "' class='btn btn-transparent search-page-previous input-btn'>"
                //        + "<img alt='Previous Page' src='/src/CSS/css/images/chevron-left.svg' /><span class='sr-only'>Search Previous</span>"
                //        + "</button>";

                //    var nextPageButton = "<button data-skip='" + nextpage + "'data-take='" + data.Take + "' class='btn btn-transparent search-page-next input-btn'>"
                //        + "<img alt='Next Page' src='/src/CSS/css/images/chevron-right.svg' /><span class='sr-only'>Search Previous</span>"
                //        + "</button>";

                //    //if first page
                //    if (parseInt(data.Skip) === 0) {
                //        previouspagehtml = "";
                //        pagesHtml = previouspagehtml + currentpagehtml + nextPagegtml;
                //    }
                //    else {
                //        currentPageNumber = parseInt(data.Skip) / parseInt(data.Take) + 1;
                //        previouspagenumber = currentPageNumber - 1;
                //        nextpagenumber = currentPageNumber + 1;

                //        currentpagehtml = "<button data-skip='" + data.Skip + "' data-take='" + data.Take + "' class='btn btn-transparent search-page-number search-active-page'>"
                //            + currentPageNumber
                //            + "</button>";

                //        previouspagehtml = "<button data-skip='" + previouspage + "' data-take='" + data.Take + "' class='btn btn-transparent search-page-number'>"
                //            + previouspagenumber
                //            + "</button>";
                //        nextPagegtml = "<button data-skip='" + nextpage + "' data-take='" + data.Take + "' class='btn btn-transparent search-page-number'>"
                //            + nextpagenumber
                //            + "</button>";

                //        pagesHtml = previouspagehtml + currentpagehtml + nextPagegtml;

                //        //if last page
                //        if (currentPageNumber === totalpages) {
                //            pagesHtml = previouspagehtml + currentpagehtml;

                //        }
                //    }

                //    var PageAdd = "";

                //    //if first page
                //    if (parseInt(data.Skip) === 0) {

                //        PageAdd = "<div class='d-flex justify-content-center'>"
                //            + pagesHtml
                //            + nextPageButton
                //            + "</div>";
                //        $(PageAdd).appendTo("#search-results-pagination-wrapper");
                //    }
                //    //if last page
                //    else if (currentPageNumber === totalpages) {
                //        PageAdd = "<div class='d-flex justify-content-center'>"
                //            + previousPageButton
                //            + pagesHtml
                //            + "</div>";
                //        $(PageAdd).appendTo("#search-results-pagination-wrapper");
                //    }
                //    else {
                //        PageAdd = "<div class='d-flex justify-content-center'>"
                //            + previousPageButton
                //            + pagesHtml
                //            + nextPageButton
                //            + "</div>";

                //        $(PageAdd).appendTo("#search-results-pagination-wrapper");
                //    }

                //}

            }
            else {
                $("#search-results-div").empty();
                $("#search-results-pagination-wrapper").hide();
                var Error = "<div class='col-12 text-center text-uppercase search-result-wrapper'>"
                    + "<div class='h1'> No Results Found for " + searchquery + "</div>"
                    + "</div>";
                $(Error).appendTo("#search-results-div");
            }

        },
        error: function (xhr, textStatus, errorThrown) {
            console.log(errorThrown);
            console.log(textStatus);
        }
    });
};


    //#endregion