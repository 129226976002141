const elem = document.querySelectorAll(
  '[data-alias-name="slideUpFeatureCardsComponentRow"]'
);

if (elem == null) return;

elem.forEach((item) => {
  const bgElem = item.querySelector("[data-umb-background-image] img");

  if (bgElem.complete) {
    item.style.setProperty(
      "--umb-background-image",
      `url(${bgElem.currentSrc})`
    );
  } else
    bgElem.onload = () => {
      item.style.setProperty(
        "--umb-background-image",
        `url(${bgElem.currentSrc})`
      );
    };
});
