﻿const $menuButton = document.getElementById("menu-button");
const $body = document.getElementById("body");;
const $overlay = document.getElementById("overlay");
const $boatsLink = document.getElementById("boats-link");
const $backButtons = document.querySelectorAll(".back-button");
const $subNavLinks = document.querySelectorAll(".nav-link");
const $mastwrapper = document.getElementById("masthead-wrapper");

// navActive toggles display of nav / not visible to users
let navActive = false;

// showNav toggles the animation of the navigation
let showNav = false;

// boatsActive toggles the animation of the navigation at desktop
let boatsActive = false;

let subNavActive = false;
let subNavIdx = 0;

// shared function that closes all the navigation and subnav and resets teh boats active state for desktop
function closeNav() {
    if (boatsActive) {
        document.getElementById("nav-list-item-0").classList.remove("boats-active");
        boatsActive = false;
    }

    if (subNavActive) {
        document
            .getElementById("nav-list-item-" + subNavIdx)
            .classList.remove("active");
        subNavActive = false;
        subNavIdx = 0;
    }

    $body.classList.remove("show-nav");
    showNav = false;

    // setTimeout to remove the nav-active class which toggles the display of the nav. navigation alse to display none once nav has collapsed.
    setTimeout(() => {
        $body.classList.remove("nav-active");
        navActive = false;
    }, 900);
}



// event handler for the masthead navigation hamburger button

$menuButton.addEventListener("click", () => {
    if (!navActive) {
        // Sets nav the nav to display block in the DOM,
        $body.classList.add("nav-active");

        //the use timeout to add the `show-nav` class to reveal the nav and trigger the animation.
        setTimeout(() => {
          
            $body.classList.add("show-nav");
            showNav = true;
            navActive = true;

            // when opening the navigation we default to boats open at desktop
            document.getElementById("nav-list-item-0").classList.add("boats-active");
            boatsActive = true;
        }, 10);
        setTimeout(() => {
          if($mastwrapper.classList.contains("slide-out")){
            $mastwrapper.classList.remove("slide-out");
            $mastwrapper.classList.add("slide-in");
          }
        }, 100);
    } else {
        closeNav();
    }
});


// event handlers for all the subnav links.
$subNavLinks.forEach(function (element) {
    element.addEventListener("click", function (e) {
        e.preventDefault();

        if (boatsActive) {
            // close the desktop nav if it isn't already
            document
                .getElementById("nav-list-item-0")
                .classList.remove("boats-active");
            boatsActive = false;
        }

        if (subNavActive) {
            document
                .getElementById("nav-list-item-" + subNavIdx)
                .classList.remove("active");
        }
        var idx = this.dataset.idx;
       /* console.log("Clicked link with data-idx: ", idx);*/
        document.getElementById("nav-list-item-" + idx).classList.add("active");
        subNavActive = true;
        subNavIdx = idx;
    });
});

// event handlers for all the back buttons in the subnav, which are only visible at mobile
$backButtons.forEach(function (element) {
    element.addEventListener("click", function (e) {
        if (subNavActive) {
            document
                .getElementById("nav-list-item-" + subNavIdx)
                .classList.remove("active");
            subNavActive = false;
        }

        e.preventDefault();
    });
});

// always close menus and modals when the escape key is clicked
document.addEventListener("keydown", function (event) {
    if (event.keyCode === 27) {
        closeNav();
    }
});


// always close menus and modals when the overlay or background is clicked
$overlay.addEventListener("click", () => {
    closeNav();
});
